const projectId = '5f3aaecb645f0e0bf53cb93c';
const nbOfDaysToWaitBeforeAskingAgain = 30 * 6; // 6 months
const userCookiesDuration = 30 * 6; // 6 months

window.axeptioSettings = {
    /** @type {string} clientId ObjectID corresponding to the project object */
    clientId: projectId,
    /**
     * Number of days used the cookie holding user's choices should be active
     * @type {number}
     */
    userCookiesDuration: userCookiesDuration
};

/**
 * SDK integration https://developers.axeptio.eu/sdk/integration-du-sdk#integration
 * **/
(function (d, s) {
    var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
    e.async = true;
    e.src = '//static.axept.io/sdk.js';
    t.parentNode.insertBefore(e, t);
})(document, 'script');

/**
 * reload page after user choice
 * https://developers.axeptio.eu/site-integration/special-cases-spa-or-react
 */
(window._axcb = window._axcb || []).push(function (sdk) {
    sdk.on('consent:saved', function (choices) {
        window.location.reload();
    });
});

function shouldAskForCookieConsent(cookieDepositDate) {
    const now = new Date();
    return (now.getTime() - cookieDepositDate) / (1000 * 60 * 60) >
        nbOfDaysToWaitBeforeAskingAgain;
}

/**
 * make the widget appear again after nbOfDaysToWaitBeforeAskingAgain days the user gave his consent
 * https://developers.axeptio.eu/advanced-settings/duration-of-consents-conservation#3-the-widget-hides-after-the-user-gave-his-consent
 */
window._axcb = window._axcb || [];
window._axcb.push(function (sdk) {
    try {
        var json = sdk.userPreferencesManager.readChoicesFromCookies(
            'axeptio_cookies'
        );
        if (json && json.$$date) {
            var acceptedAt = new Date(json.$$date);
            if (shouldAskForCookieConsent(acceptedAt.getTime())) {
                window.openAxeptioCookies();
            }
        }
    } catch (err) {
        console.warn('Axeptio could not read previous choices nor parse consent date', err);
    }
});
